var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-3"},[_vm._v(" Open "),_c('b',[_vm._v("Odyssey")]),_vm._v(", go to the "),_c('b',[_vm._v("Labs")]),_vm._v(" tab, find the Kinetics section and click on "),_c('b',[_vm._v("M2 Reactive Collisions between Molecules.")]),_vm._v(" Follow the instructions on the screen and click on all the available buttons. ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" Which of the following reactant orientations lead to a reaction? "),_c('v-select',{staticStyle:{"max-width":"200px"},attrs:{"items":_vm.items1,"item-text":"text","item-value":"value","label":"Select option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}})],1),_c('img',{staticClass:"mb-4",staticStyle:{"max-width":"350px"},attrs:{"src":"/img/assignments/UCIrvine/reactantOrientations.png"}}),_c('p',{staticClass:"mb-2"},[_vm._v(" Note: the translational & vibrational energy is equivalent to the negative of an enthalpy of formation. ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" What is the translation & vibrational energy of the products ("),_c('u',[_vm._v("before")]),_vm._v(" the reaction)? (i.e. what is the "),_c('stemble-latex',{attrs:{"content":"$-\\Delta\\text{H}_{\\text{f(reactants)}}$)?"}}),_c('v-select',{staticClass:"ml-1",staticStyle:{"max-width":"200px","display":"inline-block"},attrs:{"items":_vm.items2,"item-text":"text","item-value":"value","label":"Select option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}}),_c('stemble-latex',{attrs:{"content":"$\\text{KJ/mol}$"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" What is the translation & vibrational energy of the products ("),_c('u',[_vm._v("after")]),_vm._v(" the reaction)? (i.e. what is the "),_c('stemble-latex',{attrs:{"content":"$-\\Delta\\text{H}_{\\text{f(products)}}$)?"}}),_c('v-select',{staticClass:"ml-1",staticStyle:{"max-width":"200px","display":"inline-block"},attrs:{"items":_vm.items2,"item-text":"text","item-value":"value","label":"Select option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}}),_c('stemble-latex',{attrs:{"content":"$\\text{KJ/mol}$"}})],1),_c('p',{staticClass:"mb-1"},[_vm._v(" The chemical reaction is "),_c('b',[_vm._v(" [A] ")]),_vm._v(" because the translation & vibrational energy "),_c('b',[_vm._v(" [B] ")]),_vm._v(" the products is greater than the translational & vibrational energy "),_c('b',[_vm._v(" [C] ")]),_vm._v(" the reactants. The "),_c('stemble-latex',{attrs:{"content":"$\\Delta\\text{H}_\\text{rxn}$"}}),_vm._v(" is calculated to be "),_c('b',[_vm._v(" [D] ")]),_c('stemble-latex',{attrs:{"content":"$\\text{KJ/mol}$."}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" [A]: "),_c('v-select',{staticStyle:{"max-width":"200px","display":"inline-block"},attrs:{"items":_vm.items3,"item-text":"text","item-value":"value","label":"Select option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input4),callback:function ($$v) {_vm.$set(_vm.inputs, "input4", $$v)},expression:"inputs.input4"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" [B]: "),_c('v-select',{staticStyle:{"max-width":"200px","display":"inline-block"},attrs:{"items":_vm.items4,"item-text":"text","item-value":"value","label":"Select option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input5),callback:function ($$v) {_vm.$set(_vm.inputs, "input5", $$v)},expression:"inputs.input5"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" [C]: "),_c('v-select',{staticStyle:{"max-width":"200px","display":"inline-block"},attrs:{"items":_vm.items4,"item-text":"text","item-value":"value","label":"Select option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input6),callback:function ($$v) {_vm.$set(_vm.inputs, "input6", $$v)},expression:"inputs.input6"}})],1),_c('p',{staticClass:"mb-3"},[_vm._v(" [D]: "),_c('v-select',{staticStyle:{"max-width":"200px","display":"inline-block"},attrs:{"items":_vm.items5,"item-text":"text","item-value":"value","label":"Select option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input7),callback:function ($$v) {_vm.$set(_vm.inputs, "input7", $$v)},expression:"inputs.input7"}})],1),_c('p',{staticClass:"mb-1"},[_vm._v(" Lower the energy using the slider directly to the right of Total Energy (Molar) in the Properties table. Start by sliding the value to half of the "),_c('stemble-latex',{attrs:{"content":"$67.6\\,\\text{kJ/mol}$"}}),_vm._v(" displayed. Toggle to a slower speed using the slide just above the + Add Property button. The minimum amount of energy that needs to be put in the system to cause a reaction is "),_c('b',[_vm._v(" [E] ")]),_vm._v(" kJ/mol. What is this energy called? "),_c('b',[_vm._v(" [F] ")])],1),_c('p',{staticClass:"mb-2"},[_vm._v(" [E]: "),_c('v-select',{staticStyle:{"max-width":"200px","display":"inline-block"},attrs:{"items":_vm.items6,"item-text":"text","item-value":"value","label":"Select option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input8),callback:function ($$v) {_vm.$set(_vm.inputs, "input8", $$v)},expression:"inputs.input8"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" [F]: "),_c('v-select',{staticStyle:{"max-width":"200px","display":"inline-block"},attrs:{"items":_vm.items7,"item-text":"text","item-value":"value","label":"Select option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input9),callback:function ($$v) {_vm.$set(_vm.inputs, "input9", $$v)},expression:"inputs.input9"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }