<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Open <b>Odyssey</b>, go to the <b>Labs</b> tab, find the Kinetics section and click on
        <b>M2 Reactive Collisions between Molecules.</b> Follow the instructions on the screen and
        click on all the available buttons.
      </p>
      <p class="mb-2">
        Which of the following reactant orientations lead to a reaction?
        <v-select
          v-model="inputs.input1"
          style="max-width: 200px"
          :items="items1"
          item-text="text"
          item-value="value"
          label="Select option"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <img
        class="mb-4"
        style="max-width: 350px"
        src="/img/assignments/UCIrvine/reactantOrientations.png"
      />

      <p class="mb-2">
        Note: the translational & vibrational energy is equivalent to the negative of an enthalpy of
        formation.
      </p>
      <p class="mb-2">
        What is the translation & vibrational energy of the products (<u>before</u> the reaction)?
        (i.e. what is the
        <stemble-latex content="$-\Delta\text{H}_{\text{f(reactants)}}$)?" />
        <v-select
          v-model="inputs.input2"
          class="ml-1"
          style="max-width: 200px; display: inline-block"
          :items="items2"
          item-text="text"
          item-value="value"
          label="Select option"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        <stemble-latex content="$\text{KJ/mol}$" />
      </p>

      <p class="mb-2">
        What is the translation & vibrational energy of the products (<u>after</u> the reaction)?
        (i.e. what is the
        <stemble-latex content="$-\Delta\text{H}_{\text{f(products)}}$)?" />
        <v-select
          v-model="inputs.input3"
          class="ml-1"
          style="max-width: 200px; display: inline-block"
          :items="items2"
          item-text="text"
          item-value="value"
          label="Select option"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        <stemble-latex content="$\text{KJ/mol}$" />
      </p>

      <p class="mb-1">
        The chemical reaction is <b>&nbsp;[A]&nbsp;</b> because the translation & vibrational energy
        <b>&nbsp;[B]&nbsp;</b> the products is greater than the translational & vibrational energy
        <b>&nbsp;[C]&nbsp;</b> the reactants. The
        <stemble-latex content="$\Delta\text{H}_\text{rxn}$" />
        is calculated to be <b>&nbsp;[D]&nbsp;</b>
        <stemble-latex content="$\text{KJ/mol}$." />
      </p>
      <p class="mb-2">
        [A]:
        <v-select
          v-model="inputs.input4"
          style="max-width: 200px; display: inline-block"
          :items="items3"
          item-text="text"
          item-value="value"
          label="Select option"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
      <p class="mb-2">
        [B]:
        <v-select
          v-model="inputs.input5"
          style="max-width: 200px; display: inline-block"
          :items="items4"
          item-text="text"
          item-value="value"
          label="Select option"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
      <p class="mb-2">
        [C]:
        <v-select
          v-model="inputs.input6"
          style="max-width: 200px; display: inline-block"
          :items="items4"
          item-text="text"
          item-value="value"
          label="Select option"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
      <p class="mb-3">
        [D]:
        <v-select
          v-model="inputs.input7"
          style="max-width: 200px; display: inline-block"
          :items="items5"
          item-text="text"
          item-value="value"
          label="Select option"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-1">
        Lower the energy using the slider directly to the right of Total Energy (Molar) in the
        Properties table. Start by sliding the value to half of the
        <stemble-latex content="$67.6\,\text{kJ/mol}$" />
        displayed. Toggle to a slower speed using the slide just above the + Add Property button.
        The minimum amount of energy that needs to be put in the system to cause a reaction is
        <b>&nbsp;[E]&nbsp;</b> kJ/mol. What is this energy called? <b>&nbsp;[F]&nbsp;</b>
      </p>

      <p class="mb-2">
        [E]:
        <v-select
          v-model="inputs.input8"
          style="max-width: 200px; display: inline-block"
          :items="items6"
          item-text="text"
          item-value="value"
          label="Select option"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
      <p class="mb-2">
        [F]:
        <v-select
          v-model="inputs.input9"
          style="max-width: 200px; display: inline-block"
          :items="items7"
          item-text="text"
          item-value="value"
          label="Select option"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LCQ3S2Q4',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
      items1: [
        {text: 'a', value: 'a'},
        {text: 'b', value: 'b'},
        {text: 'c', value: 'c'},
        {text: 'd', value: 'd'},
        {text: 'e', value: 'e'},
      ],
      items2: [
        {text: '$67.5$', value: 'sixtySeven'},
        {text: '$130.6$', value: 'oneThirty'},
        {text: '$43.9$', value: 'fortyThree'},
        {text: '$0$', value: 'zero'},
      ],
      items3: [
        {text: 'exothermic', value: 'exo'},
        {text: 'endothermic', value: 'endo'},
        {text: 'spontaneous', value: 'spontaneous'},
        {text: 'nonspontaneous', value: 'non'},
      ],
      items4: [
        {text: 'after', value: 'after'},
        {text: 'before', value: 'before'},
        {text: 'during', value: 'during'},
      ],
      items5: [
        {text: '$-63.0$', value: 'sixtyThree'},
        {text: '$-130.6$', value: 'oneThirty'},
        {text: '$43.9$', value: 'fortyThree'},
        {text: '$67.6$', value: 'sixtySeven'},
      ],
      items6: [
        {text: '<$30$', value: 'lessThan'},
        {text: 'between $40$ and $45$', value: 'between'},
        {text: '$48-52$', value: 'fortyEightFiftyTwo'},
        {text: '$67.6$', value: 'sixtySeven'},
      ],
      items7: [
        {text: 'activation energy', value: 'activation'},
        {text: 'total energy', value: 'total'},
        {text: 'enthalpy of reaction', value: 'enthalpy'},
        {text: 'transition state', value: 'transition'},
        {text: "Gibb's Free Energy", value: 'gibbs'},
      ],
    };
  },
};
</script>
<style scoped></style>
